// Font Awesome
@import "@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "@fortawesome/fontawesome-free/scss/solid.scss";
@import "@fortawesome/fontawesome-free/scss/regular.scss";
@import "@fortawesome/fontawesome-free/scss/brands.scss";

@import "assets/scss/_custom-variable.scss"; // overwrite the theme
// MDB SCSS
@import "mdb-angular-ui-kit/assets/scss/mdb.scss";

@import "assets/scss/_custom-style.scss";

html {
  font-size: 14px;
}
