// avatar
.avatar {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    overflow: hidden;

    &-lg {
        width: 71.63px !important;
        height: 70px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
    }

    img {
        vertical-align: top;
    }

    &-md {
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        overflow: hidden;
    }

    &-xl {
        width: 120px;
        height: 120px;
    }

    &-lg {
        width: 70px;
        height: 70px;
        line-height: 65px;
        text-align: center;
        overflow: hidden;
        position: relative;
        // border: 5px solid $light;

        i {
            color: $primary;
            font-size: 1.5rem;
        }
    }
}

.separator {
    display: inline-block;
    text-align: center;

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $gray-light;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.bg-image {
    height: 5rem;
}

.fa-ul {
    margin-left: 1.25rem !important;
}

nav {
    ol>li {
        &.breadcrumb-item {
            font-size: large;
        }

        a.active {
            color: $primary !important;
        }
    }
}

.note {
    &-body {
        height: calc(100vh - 235px);
    }

    &-footer {
        box-shadow: 0 4px 12px #00000012, 0 2px 4px #0000000d;
        background: $white;
    }
}

.map-legends {
    .fa-circle, 
    .fa-path {
        color: #3cb371;
    }
}

.fixed {
    position: fixed;
    padding: 10px;
    height: 100vh;
}
.scrollable-form-nav {
    ol {
        list-style: none;
        padding: 0;

        & li {
            padding: 10px 5px 10px 5px;
            border-radius: 4px;
            margin-bottom: 2px;
            font-size: 14px;
            cursor: pointer;
            &:hover {
                background-color: $primary;
                color: $primary-lighter
            }
            &.active {
                background-color: $primary;
                color: $primary-lighter
            }
        }
    }
}

.back-text {
    margin-left: 20px;
    margin-bottom: 15px;
    font-size: 16px;
    cursor: pointer;
    color: $primary;
}

.scrollable-form-container .fw-bold {
    font-size: 16px;
}

$utilities: (
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      20: 0.2,
      25: .25,
      50: .5,
      75: .75,
      100: 1,
        ))
);

.blurred {
    filter: blur(2px);
  -webkit-filter: blur(2px);
 
  & button {
    pointer-events: none;
  }
}

.warning-badge {
    border: 1px solid $warning;
    background-color: $white;
    padding: 6px;
    border-radius: 6px;

    & i {
        margin: 2px 6px 0 0;
    }
}

input[type=radio] {
    padding: 0.5em;
    -moz-background-clip: padding-box;  
    -webkit-background-clip: padding-box;  
    background-clip: padding-box; 
    -webkit-appearance: none;
    border-radius: 0.5px; 
    outline: 0.1em solid #000;
    outline-offset: 0.1em;
}
  
input[type=radio]:checked {
    display: inline-block;
    -moz-background-clip: padding-box;  
    -webkit-background-clip: padding-box;  
    background-clip: padding-box; 
    -webkit-appearance: none;
    border-radius: 2.5px; 
    background-color: $primary;
}

.form-check-label {
    margin-left: 10px;
    margin-right: 30px;
}

.edge-browser .scrollable-form-container {
    margin-left: 600px !important;
}

.scrollable-form-container {
    margin-left: 420px;
    
    @media (min-width: 1920px) {
        margin-left: 600px;
    }
    @media (max-width: 1400px) { // Adjust for large screens (lg)
        margin-left: 420px;
    }
    @media (max-width: 992px) { // Adjust for large screens (lg)
        margin-left: 285px;
    }

    @media (max-width: 768px) { // Adjust for medium screens (md)
        margin-left: 185px;
    }

    @media (max-width: 576px) { // Adjust for small screens (sm)
        margin-left: 100px;
    }
}

.bg-stepper {
    background-color: #ededed;
}

.text-center {
    text-align: center;
}

.stepper-rt-border {
    border-left: 1px solid #c0bfbf;
}

.blue{
    color:#5592be;
    font-size: 14px;
}

.audio-label{
    font-size: 14px;
}

.configure-checkbox{
    width: 18px;
    height: 18px;
    margin-left: 10px;
    margin-top: 2px;
}

.configure-text{
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
}

.configure-label{
    display: flex;
}

.test-font{
    font-size: 15px;
}
