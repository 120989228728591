//poppins google font
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

$primary: #5592be;
$primary-darker: #730d19;
$primary-dark: #25567c;
$primary-light: #ea7b88;
$primary-lighter: #fbe9eb;
$primary-lightest: #ffe5cd;
$accent-1: #fff0b4;
$secondary: #54585e;
$secondary-gray: #54585e;
$secondary-dark: #1c1917;
$secondary-light: #eeeff7;
$gray: #666666;
$gray-light: #ededed;
$gray-lighter: #f8f9fa;
$gray-lightest: #fafafa;
$gray-dark: #212529;
$success: #007750;
$success-light: #7dd4b5;
$info: #116a9f;
$warning: #e6a313;
$danger: #d32f2f;
$danger-darker: #7f1c1c;
$white: #ffffff;
$link-color: $primary;
$font-family-base: "Poppins", sans-serif;
$border-radius: 4px;

$theme-colors: (
  "primary": $primary,
  "primary-darker": $primary-darker,
  "primary-dark": $primary-dark,
  "primary-light": $primary-light,
  "primary-lighter": $primary-lighter,
  "primary-lightest": $primary-lightest,
  "accent-1": $accent-1,
  "secondary": $secondary,
  "secondary-gray": $secondary-gray,
  "secondary-dark": $secondary-dark,
  "secondary-light": $secondary-light,
  "gray": $gray,
  "gray-light": $gray-light,
  "gray-lighter": $gray-lighter,
  "gray-lightest": $gray-lightest,
  "gray-dark": $gray-dark,
  "success": $success,
  "success-light": $success-light,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "danger-darker": $danger-darker,
);
// contrast ratio
$min-contrast-ratio: 2;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px,
  xxl: 1920px,
);

// spacer
$spacer: 1rem;
$spacers: (
  0: 0, 
  1: $spacer * .5, //8px
  2: $spacer, //16px
  3: $spacer * 2, //32px
  4: $spacer * 4  //64px
);

// Typography

$font-weight-normal: 400;
$font-weight-bold: 500;
$headings-font-weight: $font-weight-bold;
$headings-color: #000000;

$font-weight-base: $font-weight-normal;
$font-size-base: 0.875rem;
$font-size-sm: 0.75rem;

// font sizes
$font-sizes: (
  1: $font-size-sm,
  2: $font-size-base,
);

// Header tags
$h1-font-size: $font-size-base * 2.5; //40px
$h2-font-size: $font-size-base * 2.25; //36px
$h3-font-size: $font-size-base * 2; //32px
$h4-font-size: $font-size-base * 1.75; //28px
$h5-font-size: $font-size-base * 1.5; //24px
$h6-font-size: $font-size-base * 1.25; //20px

//divider
$breadcrumb-divider: quote(">");

// accordion
$accordion-button-padding-y: 1rem;
$accordion-button-padding-x: 1.25rem;
$accordion-border-radius: 4px;

// dropdown
$dropdown-min-width-md: 15rem;
$dropdown-border-radius: 0.25rem;
// box-shadow
$dropdown-box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
  rgba(9, 30, 66, 0.31) 0px 0px 1px;
// layout container
$grid-gutter-width: 2.5rem;
$container-padding-x: calc($grid-gutter-width / 2);
// $container-max-widths: (
//   xl: 1170px,
//   xxl: 1170px,
// );

$gutters: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1440px,
);

// font weight
$font-weight-medium: 500;

// navbar
$navbar-height: 80px;
$nav-link-padding-x: 0.625rem;
$zindex-navbar: 10;
$navbar-light-active-color: $white;
$navbar-light-color: $secondary;
$navbar-padding-top: 0.5rem;

// button
$btn-border-width: 1px;
$btn-border-radius: 4px;
$btn-height: 2.5rem;
$input-btn-line-height: 1;
$btn-line-height: $input-btn-line-height;
$btn-font-size: 1rem;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-disabled-opacity: 0.4;
$btn-hover-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2),
  0 4px 10px 0 rgba(0, 0, 0, 0.1);

// overwrite mdb variable
$btn-padding-left: 1rem;
$btn-padding-right: 1rem;

// button sm
$btn-height-sm: 1.875rem;
$btn-floating-width-sm: $btn-height-sm;
$btn-floating-height-sm: $btn-height-sm;

// button lg
$btn-height-lg: 3.125rem;
$btn-font-size-lg: 1rem;
$btn-padding-top-lg: 0.765rem;
$btn-padding-bottom-lg: $btn-padding-top-lg;

// button floating
$btn-floating-width: $btn-height;
$btn-floating-height: $btn-height;
$btn-floating-width-lg: $btn-height-lg;
$btn-floating-height-lg: $btn-height-lg;

// form

$input-height: 2.5rem;
$input-height-sm: $btn-height-sm;
$input-font-size: 0.875rem;
$input-font-size-sm: 0.75rem;
$input-padding-x: 1rem;
$input-padding-y: 0.5rem;
$input-group-padding-y: $input-padding-y;
$input-group-padding-x: $input-padding-x;
$label-color: $secondary;
$input-height-lg: $btn-height-lg;
$input-disabled-bg: $secondary-light;
$input-text-disabled: darken($secondary-light, 30%);
$input-border-color: transparent;
$input-focus-border-color: $gray;
$input-focus-border-width: 1px;
$form-label-margin-bottom: 0.25rem;
$form-label-font-size: $font-size-sm;
$form-label-line-height: 1;
$form-label-color: #000000;

// checkboxes
$form-check-input-border-width: 1px;
$form-check-input-border: $form-check-input-border-width solid $gray;
$form-check-input-border-radius: 0.25rem;
$form-check-input-border-color: $gray;

// input group
$input-group-height: $input-height;
$input-group-addon-bg: $gray-lighter;

// input lg
$input-bg: $gray-light;
$input-bg-clear: transparent;
$input-group-lg-height: 3.125rem;
$input-border-radius: 0.25rem;
$input-border-radius-lg: 0.25rem;
$btn-border-radius-lg: 0.25rem;

// select
$input-disabled-background-color: $gray-light;
$select-optoin-font-size: $input-font-size;

// modal
$modal-sm: 400px;
$modal-md: 700px;
$modal-inner-padding: $spacer * 2;
$modal-footer-margin-between: 1rem;
$modal-footer-border-width: 0;

$modal-header-padding-y: calc($modal-inner-padding / 2);
$modal-header-padding-x: $modal-inner-padding;

// table
$table-cell-padding-y: 0.5rem; // 8px
$table-cell-padding-x: 1rem; // 8px
$table-border-color: $secondary-light;
$table-hover-bg: $primary-lighter;
$table-color: $secondary-dark;
$table-th-font-weight: $font-weight-bold;
$table-font-size: $font-size-base;
$table-color: $gray;
// $table-striped-bg: $gray-light;
$table-striped-order: even;

// sidenav
$sidenav-width: 250px;
$sidenav-link-font-size: 0.875rem;
$sidenav-transform: translateX(0);
$sidenav-background-color: $gray-dark;
$sidenav-link-border-radius: 0;
$sidenav-link-active-focus-background-color: rgba($secondary-light, 0.1);

// stepper
$stepper-container-radius: 7px;

// card
$card-cap-padding-y: $spacer * 2;
$card-cap-padding-x: $spacer * 2;
$card-spacer-y: $spacer * 2;
$card-spacer-x: $spacer * 2;
$card-border-radius: 8px;
$card-box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);

// alert
$alert-bg-scale: -90%;

// badge
$badge-font-size: 0.75rem;
$badge-font-weight: 500;
$badge-border-radius: 2px;
// $badge-padding-y: ;
// $badge-padding-x: ;

// nav
$nav-tabs-vertical-link-border-width: 0 2px 0 0;
$nav-tabs-link-hover-background-color: transparent;
$nav-tabs-link-active-bg: transparent;
$tab-content-padding-y: 0.625rem;
$tab-content-padding-x: 1.25rem;
$nav-tabs-link-font-size: 0.875rem;
$nav-tabs-link-font-weight: 400;
$nav-tabs-vertical-link-active-border-width: 0 2px 0 0;
$nav-tabs-vertical-border-width: 0 1px 0 0;

// Custom Navtabs css
$nav-tabs-border-radius-top-left: 4px;
$nav-tabs-border-radius-top-right: 4px;
$custom-nav-tabs-padding-x: 0;
$custom-nav-tabs-padding-y: 0;

//  vertical tabs
$tabs-vertical-sidebar-height: calc(100vh - 60px);
$tabs-vertical-content-width: calc(100% - 40rem);

// list
$list-group-item-font-size: 0.875rem;
$list-group-sm-item-padding-y: 0;
$list-group-sm-item-padding-x: 0;

//  print content
// $print-content-font-size: 0.875rem;
// $print-content-font-size-md: 0.75rem;
// $print-content-font-size-sm: 0.65rem;
